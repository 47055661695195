<template>
  <div class="forgetPasswordSteps">
    <a-steps :current="current">
      <a-step v-for="(item,index) in steps" :key="item.title" :title="item.title" @click="nextStep(index)"/>
    </a-steps>
    <h3 class="my-10">{{ steps[current].title }}</h3>
    <div class="steps-content">
      <a-form v-show="current===0" :form="form" @submit="handleSubmit">
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="手机号码"
        >
          <a-input
            v-decorator="['phone', { rules: [{ required: true, message: '请填写手机号码' }, { pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/, message: '请输入正确的手机号码' }]}]" />
        </a-form-item>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="验证码"
        >
          <a-row :gutter="8">
            <a-col :span="12">
              <a-input v-decorator="['code', { rules: [{ required: true, message: '请填写验证码' }]}]" style="width: 100%" />
            </a-col>
            <a-col :span="7">
              <a-button :disabled="this.code.status === 1" size="small" type="primary" @click="getCode">{{ codeText }}</a-button>
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="新密码">
          <a-input
            placeholder="请输入新密码"
            v-decorator="['newPassword',{rules: [
              {required: true,message: '请输入新密码',},
              {
                min: 6,
                message: '输入的密码必须大于6位',
              },
              {validator: validateToNextPassword,}]}]"
            type="password"
          />
        </a-form-item>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          has-feedback
          label="再次确认密码">
          <a-input
            v-decorator="[
              'confirm',
              {rules: [{required: true,message: '请再次输入您的密码',},{validator: compareToFirstPassword,}],},
            ]"
            type="password"
            @blur="handleConfirmBlur"
          />
        </a-form-item>
        <a-form-item>
          <a-button html-type="submit" type="primary">
            修改密码
          </a-button>
        </a-form-item>
      </a-form>
<!--                sub-title="修改密码需要1-5分钟时间, 请您耐心等待"-->
      <div v-show="current===1" class="flex-column flex-row-center flex-col-center">
        <a-result
          status="success"
          title="您已经成功修改密码!"
        >
          <template #extra>
            <a-button key="console" type="primary" @click="$router.push('/login')">
              立刻登录
            </a-button>
            <a-button key="buy" @click="$router.push('/')">
              返回首页
            </a-button>
          </template>
        </a-result>
      </div>
    </div>
  </div>
</template>

<script>
import { forgetPasswordEdit, forgetPasswordSendCode } from '@/api/Login'

/**
 * @author  XuHongli
 * @date  2022/11/3 16:00
 * @version 1.0
 * @description
 */
export default {
  name: 'ForgetPassword',
  data() {
    return {
      uuid: '',
      code: {
        status: 0, // 0 获取验证码 1 已发送{count}秒 2 重新发送
        count: '',
        timer: null,
      },
      confirmDirty: false,
      form: this.$form.createForm(this),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      },
      current: 0,
      steps: [
        {
          title: '修改密码',
          content: 'First-content',
        },
        {
          title: '修改密码完成',
          content: 'Second-content',
        },
      ]
    }
  },
  computed: {
    codeText () {
      if (this.code.status === 1) {
        return `验证码已发送 ${this.code.count}秒`
      } else if (this.code.status === 2) {
        return `重新发送`
      } else {
        return `获取验证码`
      }
    }
  },
  methods: {
    getCode() {
      const { form: { validateFields } } = this
      validateFields(['phone'], (err, values) => {
        console.log('获取验证码触发事件', err, values)
        if (!err) {
          console.log(values)
          const times = 60 // 倒计时时间
          if (!this.timer) {
            forgetPasswordSendCode(values.phone).then((res) => {
              this.uuid = res.data
            }).catch(() => {
              this.code.status = 2
              clearInterval(this.code.timer)
              this.code.timer = null
            })
            this.code.count = times
            this.code.status = 1
            this.code.timer = setInterval(() => {
              if (this.code.count > 0 && this.code.count <= times) {
                this.code.count--
              } else {
                this.code.status = 2
                clearInterval(this.code.timer)
                this.code.timer = null
              }
            }, 1000)
          }
        }
      })
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFieldsAndScroll((err, values) => {
        console.log(values)
        if (!err) {
          console.log('Received values of form: ', values)
          const data = Object.assign(values, { uuid: this.uuid })
          forgetPasswordEdit(data).then((res) => {
            this.current++
          })
        }
      })
    },
    handleConfirmBlur(e) {
      const value = e.target.value
      this.confirmDirty = this.confirmDirty || !!value
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form
      if (value && this.confirmDirty) {
        form.validateFields(['confirm'], { force: true })
      }
      callback()
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form
      if (value && value !== form.getFieldValue('newPassword')) {
        callback('两次输入的密码必须相同')
      } else {
        callback()
      }
    },
  },
}
</script>

<style scoped>
.forgetPasswordSteps{
  width: 1100px;
  padding-top: 50px;
  margin: 0 auto;
}
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 40px;
}

.steps-action {
  margin-top: 24px;
}
</style>
